@charset 'utf-8';

@import 'normalize';
@import 'retina';
@import 'fonts';
@import 'katex';

$primary-color: #001D4B;
$secondary-color: #E8DA00;

$header-link-hover-fcolor: #3f3f11;

$base-font-family: "Times", "Times New Roman", "FreeSerif", serif;
$heading-font: $base-font-family;
$menu-font: "Open Sans", sans-serif;
$contrast-heading-font: "Open Sans Condensed";
$code-font: Hack, monospace;
$contrast-font: $menu-font;
// See also legacy.scss for possible other font usage

$carousel-title-font: $heading-font;
$carousel-title-color: #484848;
$carousel-learn-more-color: rgba(72,72,72,.6);
$carousel-nav-color: rgb(72,72,72);

$domain: "ins.uni-bonn.de";

@import 'ins-flavor';

$main-width: $grid-large-breakpoint;

@import 'fontawesome/fa-solid';
@import 'carousel';

.input-group [type="checkbox"] + label, .input-group [type="radio"] + label {
  margin-left: 1.5rem;
}

textarea.max {
  width: calc(100% - .5rem);
}

.errorlist {
  list-style: none;
  padding: 0;
  margin: .25rem .25rem;
  li {
    display: inline;
    color: #ae2f2f;
  }
}

// Move Footer to the bottom using flexbox

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  font-family: $base-font-family;
}

@media (max-width: $grid-large-breakpoint - 1px) {
  body::after {
    content: "mobile";
    display: none;
  }
}

html {
  width: 100vw;
}

@media (max-width: $grid-large-breakpoint - 1px) {
  html {
    font-size: calc(14px + .5vw) !important;
  }
}

h1,h2,h3,h4,h5,h6 {
  font-family: $heading-font;
}

h3,h4,h5,h6 {
  font-style: italic;
}

hr {
  width: 70%;
  height: 1px;
  color: #D4D4D4;
  margin: 3em auto;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

address, .address-fix {
  line-height: 1.2em;
}

figure {
  margin: $paragraph-margin;
}

li p:first-child {
  margin-top: 0;
}

li p:last-child {
  margin-bottom: 0;
}

li > p {
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: $grid-medium-breakpoint - 1px) {
  .pad-lr-sm {
    margin: $paragraph-margin;
    margin-top: 0;
    margin-bottom: 0;
  }
}

a.unobtrusive {
  color: #8e8e8e;
}

img.smooth {
  image-rendering: auto;
}

a.ext[href*="//"]:not([href*="#{$domain}"]) {
  @include icon-font {
    &::after {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: .6em;
      content: fa-content($fa-var-external-link-alt);
      display: inline-block;
      margin-left: .5ex;
      text-decoration: none;
      position: relative;
      top: -.9ex;
    }
    & + .a-post-kern {
      margin-left: -9pt;
      margin-right: 7pt;
    }
  }
}

a.pgp {
  font-family: $code-font;
  font-size: .9em;
  word-spacing: -2pt;
  text-decoration: none;
  br {
    display: block;
    content: "";
    margin-top: -.25em;
  }
}

a.locked {
  @include icon-font {
    &::after {
      font-family: "Font Awesome 5 Free";
      font-size: .6em;
      // content: "\f13e";
      content: fa-content($fa-var-lock);
      font-weight: 900;
      font-style: normal;
      display: inline-block;
      margin-left: .5ex;
      text-decoration: none;
      position: relative;
      top: -.9ex;
      opacity: .7;
    }
  }
}

.no-space-around {
  margin-top: 0;
  margin-bottom: 0;
}

code {
  font-size: 1.5ex;
}

@media (min-width: #{$grid-large-breakpoint}) {
  .content-view-span-no-padding {
    margin-left: -.5rem;
    margin-right: -3.25rem;
  }

  .content-view-span {
    @extend .content-view-span-no-padding;
    padding-top: 1pt;
    padding-bottom: 1pt;
    padding-left: .5rem;
    padding-right: 3.25rem;

    z-index: 100;
    position: relative;
  }
}

p.content-view-span {
  padding-left: 1rem;
  padding-right: 3.75rem;
}


#main-content-body hr {
  @extend .content-view-span-no-padding;
  width: 15%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: none;
  border-top: 1px dotted #c9c9c9;
}

@media (min-width: #{$grid-medium-breakpoint}) {
  .inline-center-block-md {
    display: flex;
    flex-direction: column;
    // align-items: center;
  }
  .right-md {
    text-align: right;
    padding-right: .5em;
    flex-basis: 5em !important;
    max-width: 40% !important;
    flex-shrink: 0;
  }
}

@media (max-width: $grid-medium-breakpoint - 1px) {
  .inline-center-block-md {
    text-align: center;
    .profile-img {
      width: 50%;
      margin-bottom: 1.5rem;
    }
  }
}

.center {
  text-align: center;
}

@media (max-width: $grid-medium-breakpoint - 1px) {
  .indent-sm {
    padding-left: 1em;
    margin-bottom: .5em;
  }
  .profile-img {
    // width: 200px;
    margin-bottom: 1em;
  }
}

.row.pad {
  padding: 0;
  margin: $paragraph-margin;
  .pad-fix {
    padding-left: 0;
  }
}

.row .pad-lr {
  margin: $paragraph-margin;
  margin-top: 0;
  margin-bottom: 0;
}

.row .pad-tb {
  margin: $paragraph-margin;
  margin-left: 0;
  margin-right: 0;
}

.align-baseline {
  align-items: baseline;
}

.align-top {
  align-items: top;
}

.align-center {
  align-items: center;
}

#main-content {
  flex: 1;

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(150px,1fr));
    justify-items: center;
  }

  .subgrid {
    display: grid;
    grid-template-rows: 100px auto;
    padding: 5px;
    justify-items: center;
  }

  .gridimage {
    align-self: center;
    max-height: 100%;
    img {
      max-height: 100px;
    }
  }

  .gridcaption {
    align-self: center;
    text-align: center;
  }
}

caption {
  @extend h3;
  font-size: $h4-font-size;
  margin: 1em;
  text-align: left;
}


th {
  font-family: $contrast-font;
  text-transform: uppercase;
  text-align: left;
  font-size: .9em;
  color: #4a4a4a;
}

table.packed, .smart-style table {
  box-sizing: border-box;
  width: calc(100% - 1rem);
  font-size: .8em;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  td,th {
    padding: .2em;
    font-weight: normal;
  }
  caption {
    text-transform: uppercase;
    font-family: $contrast-font;
    font-style: normal;
    font-size: 0.9rem;
    margin: 0.1rem 0;
    padding-left: .2em;
    &, a { color: #4a4a4a;}
    a {
      text-decoration: none;
    }
  }
}

@media (min-width: #{$grid-large-breakpoint}) {
  ul, ol {
    margin: $paragraph-margin;
    margin-left: 1rem;
    // margin-left: -.5em;
  }
}

ul, ol {
  ul, ol, li {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
  }

  ul, ol {
    margin-left: 0.2em;
  }

  li {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
  }

}

@media (max-width: $grid-large-breakpoint - 1px) {
  ul, ol {
    margin: $paragraph-margin;
    margin-left: 1em;
  }
}

ul {
  list-style-type: square;
}

dd {
  margin-left: 2em;
}

.smart-style {
  table {
    border-collapse: collapse;
  }

  dl {
    margin: $paragraph-margin;
  }
}

@media (min-width: #{$grid-medium-breakpoint}) {
  .smart-style {
    dl.meta {
      display: grid;
      grid-template-columns: fit-content(50%) auto;
      grid-column-gap: .5rem;
      dt {
        grid-column: 1;
        &::after {
          content: ":";
        }
      }
      dd {
        grid-column: 2;
        margin-left: 0;
      }
    }
  }
}

@media (max-width: $grid-medium-breakpoint - 1px) {
  .smart-style {
    dl.meta {
      font-family: $contrast-font;
      // background: #ccc;
      margin-bottom: 1rem;
      font-size: .9em;
      dt {
        display: block;
        font-size: 1.1em;
        &::after { content: ":"; }
      }
      dd {
        // margin-left: 0;
        margin-bottom: .5rem;
      }
    }
  }
}

@import 'top_nav';

.relative {
  position: relative;
}

$uni-bonn-logo-width: 175px;
footer {
  .uni-bonn-logo {
    // position: absolute;
    // right: 0;
    // top: .2em;
    @include retina("../images/uni_bonn_logo.png", 2, 175px 68px);
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-position: 100% 50% !important;

    // width: $uni-bonn-logo-width;
    // height: 68px;
    // margin-right: .5rem;

    display: block;
    height: 100%;

    margin: 0 .5rem;

    text-indent: 200%;
    white-space: nowrap;
    overflow: hidden;

  }

  min-height: 110px;
  margin: 0 !important;
  margin-top: 6em !important;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  nav {
    width: $main-width;
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      margin-top: -.25rem;
      li {
        display: inline-block;
        margin-right: 1em;
      }
    }

    a {
      // font-family: Exo2;
      font-family: $menu-font;
      color: #FFFFFE !important;
      text-transform: uppercase;
      font-size: 1.1em;
    }
    .copyright, .design-author {
      display: block;
      font-family: $menu-font;
    }
    .copyright {
      color: #777;
      font-size: 1em;
      a {
        text-transform: none;
        color: #9D9D9D !important;
        display: inline;
        font-size: 1em;
      }
    }
    .design-author {
      margin-top: -0.1em;
      color: #525252;
      font-size: 0.8em;
    }
  }
}

@media (max-width: calc(#{$grid-large-breakpoint*(7/9) + $uni-bonn-logo-width} + 3.5rem)) {
  .uni-bonn-logo {
    // background: red !important;
  }
}

// On homepage, the list of groups with rounded images
.short-group-list {
  max-width: 500px;
  margin: auto;
  list-style-type: none;
  padding: .5em .5em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  li .group-of, li .group-leader {
    display: block;
    text-align: center;
  }
  li .group-leader {
    color: #3b3b3b;
  }
  li .group-of {
    font-family: $contrast-heading-font;
    font-weight: bold;
    color: #646464;
    font-size: 0.6em;
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom: -4px;
  }
  li a {
    text-decoration: none;
  }
  li {
    padding: 5px;
    margin: 0.5em 0;
  }
}

// Makes a variation in the background color
.darker {
  position: relative;
  & > :first-child:before {
    content: "";
    position: absolute;
    left: -9999em;
    right: -9999em;
    top: 0;
    bottom: 0;
    background: #F3F3F3;
    z-index: -10;
  }
}

.profile-pic {
  display: block;
  margin: auto;
  position: relative;
  img {
    border-radius: 50%;
    filter: grayscale(100%);
    position: relative;
    width: 100%;
  }
  &::after {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    z-index: 10;
    display: block;
    width:  100%;
    height: 100%;
    border: 3px solid #3b3b3b;
    border-radius: 50%;
    opacity: 0.8;
  }
}

@media (min-width: #{$grid-medium-breakpoint}) {
  .profile-pic {
    width: 120px;
    height: 120px;
  }
  .short-group-list {
    li .group-of, li .group-leader {
      width: 140px;
    }
  }
}

@media (max-width: $grid-medium-breakpoint - 1px) {
  .profile-pic {
    width: 100px;
    height: 100px;
  }
  .short-group-list {
    li .group-of, li .group-leader {
      width: 110px;
      font-size: 0.8em;
    }
  }
}

.name {
  font-variant: small-caps;
}

// Main page background deco

.top-deco {
  @include retina("../images/top_bg_deco.jpg", 2, 1440px 547px, center top);
  height: 547px;
  background-size: cover;
  position: relative;
  z-index: 0;
}

@media (max-width: $grid-large-breakpoint - 1px) {
  .pull-over-deco {
    position: relative;
    top: -455px;
    // left: 70px;
    z-index: 100;
    & + footer {
      margin-top: calc(-455px + 6em) !important;
    }
  }
}

@media (min-width: #{$grid-large-breakpoint}) {
  .pull-over-deco {
    position: relative;
    top: -400px;
    // left: 70px;
    z-index: 100;
    & + footer {
      margin-top: calc(-400px + 6em) !important;
    }
  }
}

@import 'two_column';

// Formats a paragraph
// Used for 'Mr./Mrs. X has left the institute'
.info-card {
  font-family: $contrast-font;
  @extend .content-view-span;
  text-align: center;
  padding: .5em;
  font-size: .9em;
  border: #D2D0B4 1px dotted;
  border-left: none;
  border-right: none;
  background: #e9e7c8;
  padding-top: .5rem;
  padding-bottom: .5rem;
  color: #030012;
  p {
    margin: .5em 0;
  }

  &:first-child {
    margin-top: 0;
    margin-bottom: 0;
  }
}


.zick-zacks {
  // Alternating text-image blocks.
  .item {
    display: flex;
    align-items: center;
    .deco {
      padding: 0.5em;
    }
  }
  .item:nth-child(2n) {
    .content {
      order: 2;
    }
    .deco {
      order: 1;
    }
  }
}

#main-content {
  width: $main-width;
  margin: auto;
}

.mission-glimpse {
  // on start page, the excerpt of the mission statement
  font-size: 1.1rem;
  color: #070F1B;
  text-align: center;
}

.leading {
  // Parapgraph at the beginning of text, a bit larger, different font
  font-size: 1.05em;
  margin-bottom: 1.5em;
  font-family: $contrast-font;
}

@media (min-width: #{$grid-large-breakpoint}) {
  .hanging {
    $bsize: 3px;
    border-top: #{$bsize} solid #6D6C5D;
    padding-top: #{$bsize};
    margin: 0;
    color: #6D6C5D;
    font-size: 0.9rem;
    text-align: right;
    position: relative;
    // p top margin + half line height
    top: calc(1rem - #{$bsize/2} + 1.5 * 1.1rem / 2);
    width: 90%;
  }

  .space-around {
    margin-top: 3em;
    margin-bottom: 3em;
  }
}

@media (max-width: $grid-large-breakpoint - 1px) {
  .mission-glimpse {
    text-align: center;
  }
  .hanging {
    $size: 1.5px;
    color: #6D6C5D;
    text-align: center;
    font-style: italic;
    font-size: 1.3rem;
    margin-bottom: .2em;
    display: block;
    &::before, &::after {
      content: "";
      width: 15%;
      margin-left: .7em;
      margin-right: .7em;
      height: $size;
      background: #6D6C5D;
      display: inline-block;
      vertical-align: middle;
    }
  }
}


.space-around {
  margin-bottom: 3em;
}

.sep-link:not(:last-child)::after {
  content: " | ";
}

.sep-link-space:not(:last-child)::after {
  content: " ";
  display: inline-block;
  width: .5ex;
}

.display-options {
  margin-top: -1em;
}

.active-do {
  font-style: italic;
}

.min-width0 {
  min-width: 0;
}

// Personal page

.griebel-view-nav {
  @extend .content-view-span;
  border-bottom: 1px dotted #c9c9c9;
  border-top: 1px dotted #c9c9c9;
  ul {
    list-style-type: none;
    padding-left: 0;
    margin: .25rem .5rem;
  }
  li {
    margin: 0;
    display: inline-block;
    + li {
      margin-left: .5rem;
    }
  }
  a {
    @extend .unobtrusive;
  }
  .active {
    font-style: italic;
    color: #999999;
  }
}

// Publications

@counter-style pub-list {
  system: extends decimal;
  prefix: '[';
  suffix: ']  ';
}

@counter-style ins-preprint-nr {
  system: extends pub-list;
  pad: 4 "0";
}

.publication-list.preprint-list {
  list-style: decimal;
  list-style: ins-preprint-nr;

  // list-style-position: inside;
  margin-left: -.5em;
  .publication-description {
    display: inline;
  }
  .title, .btitle {
    display: inline;
  }
}

.publication-description {
  font-family: $base-font-family;

  font-size: .75rem;
  line-height: .9rem;
  // color: #535353;
  $meta-text-color: adjust-color($primary-color, $lightness: 30%, $saturation: -90%);
  color: $meta-text-color;
  a.dark {
    color: adjust-color($primary-color, $lightness: 20%, $saturation: -70%);
  }
  .title, .btitle {
    font-family: $contrast-font;
    &::after {
      content: "";
      display: block;
    }
    font-size: 1rem;
    line-height: 1.1rem;
    margin-bottom: .07rem;
    display: block;
    color: $fore-color;
  }
}

.publication-list-headline {
  margin-top: .5em;
  margin-bottom: .5em;
}

.publication-list {
  // Style the numeral
  font-family: $contrast-font;
  font-size: .9rem;
  color: #a5a5a5;
  // margin-top: -.5em;
  // margin-bottom: -.5em;
  margin-left: 1.5em;
  list-style: decimal;
  list-style: pub-list;
  li {
    line-height: 1.1em;
    margin: 0.75rem 0;
  }
  .download-section {
    // display: block;
    // font-size: 0.8em;
  }
  .bibtex {
    position: relative;
    .close {
      position: absolute;
      top: 0.75ex;
      right: 1ex;
    }
  }
  .spinner-donut {
    margin: 0.5em auto;
    display: block;
  }
}

@media (max-width: $grid-large-breakpoint - 1px) {
  .publication-list {
    &, &.publication-list.preprint-list {
      list-style-position: inside;
    }
    margin-left: -.5em;
    .publication-description {
      display: inline;
    }
    .title, .btitle {
      margin-left: -.25em;
    }
  }
}

// Research Project list

.program-name {
  font-size: .9em;
  &, a {
    font-family: $contrast-font;
    color: gray;
  }
}

.more-information {
  font-size: .9em;
  // font-style: italic;
  &, a {
    font-family: $contrast-font;
  }
}

.program-description {
  font-size: .8em;
  color: gray;
  font-style: italic;
  font-family: $contrast-font;
}

.project-title {
  font-size: 1.2em;
}

.proj-pic {
  border: 1pt solid #ddd;
}

.description-content {
  .spinner-donut {
    display: block;
    margin: auto;
    margin-top: 2em;
    text-indent: 9999em;
  }
}

// Teaching

.semester-block {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.semester {
  margin-top: .5em;
  margin-bottom: .5em;
}

.course-list {
  position: relative;
  z-index: 500;
  margin-top: 0;
  margin-bottom: 0;
  & + .course-list { margin-top: -1.5em; }
  line-height: 1.2em;
  li {
    a {
      color: #393939;
      font-size: 1.05em;
    }
    position: relative;
    padding: .2em;
    padding-left: 0;
    margin: .4em 0;
    margin-left: 0;
  }
  .subtitle {
    display: block;
    font-style: italic;
    color: #5A5A5A;
  }

  .title, .meta {
    font-family: $contrast-font;
  }

  .meta {
    display: block;
    font-size: 0.9em;
    &, a { color: #838383; }
  }
}

.var {
  @for $i from 1 through 5 {
    $c: adjust-color(adjust-hue($primary-color, ($i - 1px) * 60), $lightness: 10%, $saturation: -0%);
    &.v#{$i} .course::after { background: $c; }
    // &.v#{$i} .course .meta .type { background: $c; }
  }
  &.v6 .course::after { background: #7b7b7b; }
}

// Source Code highlighting

.codehilite {
  pre {
    * {
      font-family: $code-font;
      font-size: 0.8em;
    }
    line-height: 1rem;
    padding: .5rem;
    border-color: $secondary-color;
    background-color: darken($back-color, 5%);
    margin: 0;
  }
  // position: relative;
  margin: $paragraph-margin;
  // left: -.25rem;
  background: inherit !important;
  // width: calc(100% - 1rem + .25rem);
}

@media (max-width: $grid-large-breakpoint - 1px) {
  .codehilite {
    padding-left: calc(1em - .5rem);
    pre {
      width: calc(100% - 1rem - .5rem) !important;
    }
  }
}

:not(.katex-display) > span.katex {
  font-size: 1em;
}

// E-Mail obfuscation

@import 'email_obfuscation';

// Collapsing (Publications lists)

.notransition {
  transition: none !important;
  transition-duration: 0 !important;
  &::before, &::after {
    transition: none !important;
    transition-duration: 0 !important;
  }
}

.collapse-handle {
  position: relative;
  display: inline-block;

  @include icon-font {
    &.active {
      cursor: pointer;
      &::before { display: inline-block; }
    }
  }

  &.collapsed::before, &.collapsed::after {
    transform: rotate(-90deg);
  }
  @include icon-font {
    &::after {
      display: inline-block;
      transition-duration: .2s;
      transition-timing-function: ease-in-out;
      color: rgb(78, 78, 78);
      font-style: normal;

      position: relative;
      left: .75ex;
      bottom: .125ex;

      font-size: .7em;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: fa-content($fa-var-angle-down);
    }
  }
}

.collapse-item {
  overflow-y: hidden;
  position: relative;
  left: -2rem;
  padding-left: 2rem;
  width: 100%;
  &.collapsed {
    max-height: 0 !important;
    margin-bottom: -.25em;
    position: relative;
  }
}

@keyframes rot90 { 100% { transform:rotate(-90deg); } }


// Feedback

.feedback-meta {
  font-family: $contrast-heading-font;
  font-weight: bold;
  // text-transform: uppercase;
  font-size: 0.9rem;
  color: #595959;
  margin-bottom: 0.7em;
  margin-left: 0;
  margin-top: 2.8rem;
  .fas {
    padding-right: .5ex;
  }
  dt::after {
    content: ":";
  }
}

input.important {
  // Honeypot for generic bots
  display: none;
}

.toggle {
  overflow: hidden;
  transition: max-height .4s;
  transition-timing-function: ease-in-out;
}

.toggle.is-hidden {
  max-height: 0 !important;
}

// Top banner

#banner {
  font-family: $menu-font;
  min-height: 50px;
  position: fixed;
  background: #dfdcb0fa;
  bottom: 0;
  width: 100%;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #393607;
  p {
    margin-right: 0;
  }
  button {
    margin-left: 0;
    background: none;
  }
}
