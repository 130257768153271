// The \LaTeX and \TeX markup. \KaTeX is supported by KaTeX in math mode.

.latex sup {
  font-size: 0.85em;
  vertical-align: 0.15em;
  margin-left: -0.36em;
  margin-right: -0.15em;
}

.tex sub, .latex sub {
  vertical-align: -0.5ex;
  margin-left: -0.1667em;
  margin-right: -0.125em;
  line-height: 0;
}

.tex, .latex, .tex sub, .latex sub {
  font-size: 1em;
  text-transform: uppercase;
}

// Adapt font settings

.katex * {
  font: inherit;
}

.katex-display {
  font-size: 90%;
}

.texttt {
  font-family: monospace;
}

.smcp {
  font-variant: small-caps;
  font-feature-settings: "smcp" on;
}

.thinspace, .enspace, .quad, .qquad { display: inline-block }
.thinspace { width: .16667em; }
.enspace { width: .5em; }
.quad { width: 1em; }
.qquad { width: 2em; }

