// Logo background

.logo, #uni-link {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

#uni-link {
  display: none
}

@media (min-width: #{$grid-large-breakpoint}) {
  #uni-link {
    display: block !important;
    z-index: 1200;
    position: absolute;
    top: 57px;
    left: 95px;
    height: 14px;
    width: 98px;
  }
}


header {
  position: absolute;
  width: 100%;
  padding: 0;
  z-index: 1000;

  overflow: visible !important;

  input[type="checkbox"] {
    display: none;
  }

  ul {
    list-style-type: none;
  }

  span.drop-icon {
    display: none;
  }

  a.button {
    font-family: $menu-font;
    font-weight: bold;
    text-transform: uppercase;
  }

  .sub-menu li a {
    font-family: $menu-font;
    text-transform: uppercase;
    text-decoration: none;
    color: #ecedda;
    font-size: 0.8rem;
  }

  .sub-menu-heading {
    font-family: $contrast-font;
    margin: 0.3em 0;
    margin-bottom: -0.5em;
    color: #D9D9D3;
    font-size: 0.9em;
  }

  .sub-menu-indent {
    padding-left: 1em;
    margin: 0.5em 0;
  }
}

@media (min-width: #{$grid-large-breakpoint}) {
  #logo-box {
    width: 188px;
    min-width: 188px;
    height: 72px;
    display: inline-block;
    position: relative;
  }
  .logo {
    display: block;
    width: 188px;
    min-width: 188px;
    height: 72px;
    @include retina("../images/logo.png", 2, 188px 72px);
    &:hover {
      opacity: 1;
    }
    position: relative;
    left: 8px;
  }

  // Font styling header

  header {
    display: flex;
    justify-content: center;
    align-items: center;

    // Top level list
    & > ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      margin-left: 3.5em;
      display: inline-block;
      & > li {
        display: inline-block;
        position: relative;
      }
    }

    a.button {
      position: relative;
      font-size: 0.8rem;
      &:hover, &:focus {
        color: $header-link-hover-fcolor;
      }
    }

    li:hover > a.button {
      background: $header-link-hover-color;
      color: $header-link-hover-fcolor;
    }

    label.drop-icon {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 1em;
    }

    .sub-menu {
      visibility: hidden;
      opacity: 0;
      position: absolute;

      border-radius: $button-border-radius;

      background: #2b2b2b;
      margin: 0;
      padding: .3em .8em;
      & > ul {
        list-style-type: none;
      }
      li {
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1.1em;
      }
    }
  }

  input[type="checkbox"]:checked + .sub-menu,
  li:hover > input[type="checkbox"] + .sub-menu {
    visibility: visible;
    opacity: 1;
    transition: visibility .2s, opacity .2s;
  }
}

@media (max-width: $grid-large-breakpoint - 1px) {
  #logo-box {
    display: inline-block;
    width: 60%;
    height: 60%;
    max-height: 1rem;
  }
  .logo {
    background-image: url(../images/institute.svg);
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: block;
    height: 100%;
    width: 100%;
  }
  #small-logo {
    display: inline-block;
    background-image: url(../images/logo.svg);
    height: 65%;
    width: 15%;
    background-size: contain;
    background-position: 0 50%;
    background-repeat: no-repeat;
    margin-bottom: 2px;
  }

  #top-hamburger-handle {
    margin: 0;
    padding: 0;
    display: inline-block;
    background-image: url(../images/hamburger.svg);
    height: 35%;
    width: 15%;
    background-size: contain;
    background-position: 100% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  header {
    box-sizing: border-box;
    padding-left: 1rem;
    padding-right: 1rem;
    background: rgba(244, 244, 244, 0.7);
    min-height: 3.5rem;
    height: 4vh;
    display: flex;
    align-items: center;
    justify-content: space-between;

    // Top level list
    & > ul {
      position: absolute;
      right: 0;
      display: none;
      margin: 0;
      padding: 0;
      background: #222;
      top: 100%;
      min-width: 16em;
      & > li {
        display: block;
        // position: relative;
      }
    }

    li {
      padding: 0;
      margin: 0;
    }

    a.button {
      margin: 0;
      padding: .25em .5em;
      width: 100%;
      box-sizing: border-box;
      border-radius: 0;
      &:focus {
        background: #222;
      }
      &:hover {
        background: #333;
      }
    }

    .sub-menu {
      display: none;

      background: #444;
      margin: 0;
      padding: .25em .5em;
      padding-left: 1em;
      li {
        margin-bottom: -.1em;
        margin-top: -.1em;
      }
      li a {
        color: #ddd;
        font-size: 0.9rem;
        margin-bottom: -.2em;
      }
    }

    label.drop-icon {
      padding: 0;
      padding-left: .7em;
      display: inline-block;
      position: relative;
      bottom: 3px;
      padding-right: .5em;
      cursor: pointer;
      svg {
        transform: scale(1.7);
      }
    }

    a.button {
      color: #ccc;
    }

    input[type="checkbox"]:checked + ul {
      display: block;
    }

    input[type="checkbox"]:checked + .sub-menu {
      display: flex;
      flex-direction: column;
    }
  }
}
