//  Basic rules for body and typography
$fore-color:            #212121;                  // Text and general foreground color
$back-color:            #F9F9F9;                  // Body background color
// OS X and iOS system fonts (San Francisco) -> Windows system fonts -> Android system fonts (newer and older) -> Fallback fonts
$base-root-font-size:   16px;                     // Root font sizing for all elements [1]
$_1px:  (1px/$base-root-font-size) * 1rem;        // Calculated rem value of 1px [1]
$base-font-size:        1rem;                     // Default font sizing for all elements [2]
$base-line-height:      1.5;                      // Default line height for all elements.
$use-fluid-typography:  false;                    // Should fluid typography be used at the root element? (`true`/`false`)
$body-margin:           0;                        // Margin for the body
$apply-defaults-to-all: false;                    // Should default values be applied to all elements? (`true` or `false`) [3]
$h1-font-size:          2.1rem;                     // Font size of h1
$h2-font-size:          1.6875rem;                // Font size for h2
$h3-font-size:          1.4375rem;                // Font size for h3
$h4-font-size:          1.2rem;                // Font size for h4
$h5-font-size:          1rem;                     // Font size for h5
$h6-font-size:          0.8125rem;                // Font size for h6
$heading-line-height:   1.2em;                    // Line height for all headings
$heading-margin:        1em 0.5rem;           // Margin for all headings
$heading-font-weight:   normal;                      // Font weight for all headings
$heading-smalltext-fore-color:  #424242;          // <small> color in headings
$make-heading-smalltext-block:  true;             // Should <small> elements in headings be displayed as blocks (`true`/`false`) [4]
$heading-smalltext-b-font-size:  0.75em;          // Font size of block <small> elements in headings
$heading-smalltext-b-top-margin:-0.25rem;         // Top margin of block <small> elements in headings
$paragraph-margin:       1rem 0.5rem;                  // Margin for <p> elements
$list-margin:            0.5rem;                  // Margin for <ol> and <ul> elements
$list-left-padding:      1rem;                    // Left padding for <ol> and <ul> elements
$bold-font-weight:       bold;                     // Font weight for <b> and <strong>
$horizontal-rule-line-height:  1.25em;            // <hr> line height
$horizontal-rule-margin:       0.5rem;            // <hr> margin
$horizontal-rule-border-style: $_1px solid #8c8c8c;// Border style for horizontal rules (used in the <hr> element's border-top)
$horizontal-rule-fancy-style:  false;             // Should a fancy styling be applied for the <hr> element (`true`/`false`) [5]
$horizontal-rule-fancy-gradient:"to right, #bdbdbd, #8c8c8c, #bdbdbd";          // Gradient style for fancy horizontal rule
$blockquote-back-color:      #eeeeee;             // Background color for <blockquote>
$blockquote-fore-color:      $fore-color;         // Text color for <blockquote>
$blockquote-margin:          0.5rem;              // Margin for <blockquote>
$blockquote-padding:         0.5rem 0.5rem 1.5rem;// Padding for <blockquote>
$blockquote-sidebar-style:   4*$_1px solid #505050;// Style for the sidebar of <blockquote>
$blockquote-cite-font-size:  0.875rem;            // Font size for citation of <blockquote>
$blockquote-cite-fore-color:       #505050;       // Text color for citation of <blockquote>
$blockquote-cite-left-position:    0.625rem;      // Left padding for citation of <blockquote>
$blockquote-cite-bottom-position:  0;             // Bottom padding for citation of <blockquote>
$blockquote-border-style:          0;             // Border style for <blockquote>
$blockquote-border-radius:    0 2*$_1px 2*$_1px 0; // Border radius for <blockquote>
$blockquote-box-shadow:       none;               // Box shadow for <blockquote>
$use-default-code-fonts:      true;               // Should default font choice (monospace) be used for code elements? (`true`/`false`) [6]
$code-font-family:            monospace;  // Fonts for code elements if not default
$code-element-padding:        0.1rem 0.2rem;   // Padding for <code>
$code-element-fore-color:     $fore-color;        // Text color for <code>
$code-element-back-color:     #e6e6e6;            // Background color for <code>
$code-element-border-style:   1px solid #dbdbdb;  // Border style for <code>
$code-element-border-radius:  2*$_1px;            // Border radius for <code>
$code-element-box-shadow:     none;               // Box shadow for <code>
$pre-element-padding:         0.75rem;            // Padding for <pre>
$pre-element-fore-color:  $code-element-fore-color;  // Text color for <pre>
$pre-element-back-color:  $code-element-back-color;  // Background color for <pre>
$pre-element-border-style:    0;                  // Border style for <pre>
$pre-element-border-radius:   0 2*$_1px 2*$_1px 0;// Border radius for <pre>
$pre-element-margin:          0.5rem;             // Margin for <pre>
$pre-element-box-shadow:      none;               // Box shadow for <pre>
$add-pre-element-sidebar:     true;               // Should a fancy sidebar be added to the left side of <pre> (`true`/`false`) [7]
$pre-element-sidebar-style:   4*$_1px solid #1565c0;// Style of the sidebar of <pre>
$kbd-element-padding:         $code-element-padding;  // Padding for <kbd>
$kbd-element-fore-color:      #fafafa;            // Text color for <kbd>
$kbd-element-back-color:      #0c0c0c;            // Background color for <kbd>
$kbd-element-border-style:    0;                  // Border style for <kbd>
$kbd-element-border-radius:  $code-element-border-radius;  // Border radius for <kbd>
$kbd-element-box-shadow:     $code-element-box-shadow;     // Box shadow for <kbd>
$style-samp-element:         false;               // Should styles for <samp> be included? (`true`/`false`) [8]
//$samp-element-padding:       2px 4px;           // Padding for <samp>
//$samp-element-fore-color:    $fore-color;       // Text color for <samp>
//$samp-element-back-color:    #2196f3;           // Background color for <samp>
//$samp-element-border-style:  0;                 // Border style for <samp>
//$samp-element-border-radius: 2px;               // Border radius for <samp>
//$samp-element-box-shadow:                       // Box shadow for <samp>
//0 1px 2px rgba(0,0,0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.15);
$include-dfn-fix:      true;                      // Should the fix for Android 4.3 concerning <dfn> be included (`true`/`false`) [9]
$small-font-size:      0.75em;                    // Font size for <small> elements
$sup-font-size:        0.75em;                    // Font size for <sup> elements
$sub-font-size:        0.75em;                    // Font size for <sub> elements
$sup-top:              -0.5em;                    // <sup> top
$sub-bottom:           -0.25em;                   // <sub> bottom
$link-fore-color:          #0277bd;               // Text color for <a>
$link-visited-fore-color:  #01579b;               // Text color for visited <a>
$link-font-weight:         500;                   // Font weight for <a>
$apply-link-underline:     true;                  // Should an underline be applied to all <a> elements? (`true`/`false`) [10]
$apply-link-hover-fade:    true;                  // Should the :hover and :focus state of <a> elements use fade-out instead of a different color (`true`/`false`) [11]
//$link-hover-fore-color:  #0288d1;               // Text color for <a> when hovered or focused
$figcaption-font-size:     0.8125rem;             // Font size of <figcaption> elements
$figcaption-fore-color:    #424242;               // Text color for <figcaption> elements
//  Notes:
// [1] - The value $base-root-font-size should be in `px` only! This rule is very important, as it will determine the root
//  element's font sizing. The value of $_1px is calculated based on the value of $base-root-font-size, please do not alter
//   the way that it's calculated.
// [2] - The value of $base-font-size will only be used if the value of $apply-defaults-to-all is set to `true` (see [3]).
//  This is due to the fact that applying the value of it on the root element again will overwrite the value of
//  $base-root-font-size (see [1]). The value of this variable should be set in either `em` or `rem`.
// [3] - It is recommended to set the value of $apply-defaults-to-all to `true`, unless you want to apply extra styling
//  to elements manually.
// [4] - If the value of $make-heading-smalltext-block is `true` the <small> elements in headings will be displayed below
//  the main heading and the 2 values specified below will apply.
// [5] - If $horizontal-rule-fancy-style is set to `true` the value of $horizontal-rule-border-style will not be used and
//  a gradient with the style specified in $horizontal-rule-fancy-gradient will be applied instead.
// [6] - If the value of $use-default-code-fonts is set to `false` the value of $code-font-family will be used. `true`
//  will apply the proper fix to use monospace font without any problems on all browsers.
// [7] - if the value of $add-pre-element-sidebar is set to true, the style specified in $pre-element-sidebar-style will
//  be applied as a left border for the <pre> elements.
// [8] - If the value of $style-samp-element is set to `false`, no styling will be included in the final CSS file for
//  `samp` elements. Otherwise, the styling defined in the $samp-... variables will be applied.
// [9] - If the value of $include-dfn-fix is set to `true` a styling fix will be applied, so that the <dfn> elements are
//  displayed properly on older versions of Android (4.3-) in italics. Otherwise, said fix will not be included.
// [10] - If the value of $apply-link-underline is set to `true`, an underline will be applied to the link in its normal
//  color, as defined in $link-fore-color.
// [11] - If `$apply-link-hover-fade` is set to `true`, a fading transition will be used for the link when hovered over or
//  focused. Otherwise, the color specified in $link-hover-fore-color will be used.
//  Variables for grid elements
$use-four-step-grid:           false;             // Should the old 4-step grid system be used instead of the new (`true`/`false`) [1]
$include-parent-layout:        true;              // Should the classes for rows defining the column layout of children be included (`true`/`false`) [2]
$grid-container-name:          'container';       // Class name for the grid container
$grid-container-side-padding:  0.75rem;           // Padding for the grid container (left and right only)
$grid-row-name:                'row';             // Class name for the grid's rows
$grid-row-parent-layout-prefix:'cols';            // Class name prefix for the grid's row parents [2]
$grid-column-prefix:           'col';             // Class name prefix for the grid's columns
$grid-column-offset-suffix:    'offset';          // Class name suffix for the grid's offsets
$grid-order-normal-suffix:     'normal';          // Class name suffix for grid columns with normal priority
$grid-order-first-suffix:      'first';           // Class name suffix for grid columns with highest priority
$grid-order-last-suffix:       'last';            // Class name suffix for grid columns with lowest priorty
$grid-column-count:            18;                // Number of columns in the grid (integer value only)
$grid-column-padding:          0 0.25rem;         // Padding for the columns of the grid
$grid-small-prefix:            'sm';              // Small screen class prefix for grid
$grid-medium-breakpoint:        480px;            // Medium screen breakpoint for grid
$grid-medium-prefix:           'md';              // Medium screen class prefix for grid
$grid-large-breakpoint:        768px;             // Large screen breakpoint for grid
$grid-large-prefix:            'lg';              // Large screen class prefix for grid
//  Notes:
// [1] - If the value of $use-four-step-grid is `true`, the grid system will contain 4 breakpoints instead of 3, along with
//  the needed styles. In this case, values should be specified for $grid-extra-small-prefix and $grid-small-breakpoint.
// [2] - If the value of $include-parent-layout is `true`, the grid system will contain extra classes that will define the column
//  layout of their children. In this case, a value should be specified for $grid-row-parent-layout-prefix.
//  Variables for forms and inputs
$form-back-color:        #eeeeee;                 // Background color for forms
$form-fore-color:        $fore-color;             // Text color for forms
$form-border-style:      $_1px solid #c9c9c9;     // Border style for forms
$form-border-radius:     0;                       // Border radius for forms
$form-margin:            0.5rem;                  // Margin for forms
$form-padding:           0.75rem 0.5rem 1.125rem; // Padding for forms
$form-box-shadow:        none;                    // Box shadow for forms
$fieldset-back-color:    $form-back-color;        // Background color for fieldset
$fieldset-border-style:  $_1px solid #d0d0d0;     // Border style for fieldset
$fieldset-border-radius: 2*$_1px;                 // Border radius for fieldset
$fieldset-margin:        0.125rem;                // Margin for fieldset
$fieldset-padding:       0.5rem;                  // Padding for fieldset
$legend-font-weight:     $bold-font-weight;       // Font weight for legend
$legend-font-size:       0.875rem;                // Font size for legend
$legend-fore-color:      $form-fore-color;        // Text color for legend
$legend-padding:         0.125rem 0.25rem;        // Padding for legend
$label-padding:          0.25rem 0.5rem;          // Padding for label
$input-group-name:      'input-group';            // Class for input groups
$include-fluid-input-group:    true;              // Should fluid input grooups be included? (`true`/`false`) [1]
$input-group-fluid-name:'fluid';                  // Class name for fluid input groups
$include-vertical-input-group:  true;             // Should vertical input groups be included? (`true`/`false`) [1]
$input-group-vertical-name:   'vertical';         // Class name for vertical input groups.
$input-group-mobile-breakpoint:  767px;           // Breakpoint for fluid input group mobile view
$input-back-color:      #fafafa;                  // Background for input
$input-fore-color:      $fore-color;              // Text color for input
$input-border-style:    $_1px solid #c9c9c9;      // Border style for input
$input-border-radius:   2*$_1px;                  // Border radius for input
$input-margin:          0.25rem;                  // Margin for input
$input-padding:         0.5rem 0.75rem;           // Padding for input
$input-focus-border-color:    #0288d1;            // Border color for focused input
$input-invalid-border-color:  #d32f2f;            // Border color for invalid input
$input-disabled-opacity:      0.75;               // Opacity for disabled input
$input-readonly-back-color:   #e5e5e5;            // Background color for readonly input
$input-readonly-border-color: #c9c9c9;            // Border color for readonly input
$input-placeholder-fore-color:#616161;            // Text color for input placeholder
$select-padding-right:        1.5rem;             // Right padding for <select> element
$button-back-color:      #dcdcdc;                 // Back color for button elements
$button-back-opacity:    0.75;                    // Background opacity for button elements
$button-hover-back-opacity: 1;                    // Background opacity for button elements on hover or focus
$button-fore-color:      $fore-color;             // Text color for button elements
$button-border-style:    0;                       // Border style for button elements
$button-border-radius:   2*$_1px;                 // Border radius for button elements
$button-padding:         0.5rem 0.75rem;          // Padding for button elements
$button-margin:          0.5rem;                  // Margin for button elements
$button-box-shadow:      none;                    // Box shadow for buttons
$button-disabled-opacity: 0.75;                   // Disabled button elements opacity
$button-class-name:      'button';                // Class for custom button elements
$button-group-name:      'button-group';          // Class for button groups
$button-group-border-style:  $_1px solid #bdbdbd; // Border style for button groups
$button-group-margin:    $button-margin;          // Margin for button groups
$button-group-box-shadow:    none;                // Box shadow for button groups
$button-group-mobile-breakpoint:767px;            // Breakpoint for button group mobile view
$hide-file-inputs:                      true;     // Should a style be added that makes all <input>s of type `file` hidden? (`true`/`false`) [2]
$button-variant1-name:                  'primary'; // Class name for button variant 1
$button-variant1-back-color:            #0277bd;  // Background color for button variant 1
$button-variant1-back-opacity:          0.9;      // Background opacity for button variant 1
$button-variant1-hover-back-opacity:    1;        // Background opacity for button variant 1 on hover or focus
$button-variant1-fore-color:            #fafafa;  // Text color for button variant 1
$button-variant2-name:                  'secondary';// Class name for button variant 2
$button-variant2-back-color:            #c62828;   // Background color for button variant 2
$button-variant2-back-opacity:          0.9;       // Background opacity for button variant 2
$button-variant2-hover-back-opacity:    1;         // Background opacity for button variant 2 on hover or focus
$button-variant2-fore-color:            #fafafa;   // Text color for button variant 2
$button-variant3-name:                  'tertiary';// Class name for button variant 3
$button-variant3-back-color:            #5f9133;   // Background color for button variant 3
$button-variant3-back-opacity:          0.9;       // Background opacity for button variant 3
$button-variant3-hover-back-opacity:    1;         // Background opacity for button variant 3 on hover or focus
$button-variant3-fore-color:            #fafafa;   // Text color for button variant 3
$button-variant4-name:                  'inverse'; // Class name for button variant 4
$button-variant4-back-color:            #0c0c0c;   // Background color for button variant 4
$button-variant4-back-opacity:          0.9;       // Background opacity for button variant 4
$button-variant4-hover-back-opacity:    1;         // Background opacity for button variant 4 on hover or focus
$button-variant4-fore-color:            #fafafa;   // Text color for button variant 4
$button-style1-name:             'small';          // Class name for button style 1
$button-style1-border-style:     0;                // Border style for button style 1
$button-style1-border-radius:    $_1px;            // Border radius for button style 1
$button-style1-padding:          0.25rem 0.375rem; // Padding for button style 1
$button-style1-margin:           0.5rem;           // Margin for button style 1
$button-style2-name:             'large';          // Class name for button style 2
$button-style2-border-style:     0;                // Border style for button style 2
$button-style2-border-radius:    4*$_1px;          // Border radius for button style 2
$button-style2-padding:          0.75rem 1.125rem; // Padding for button style 2
$button-style2-margin:           0.5rem;           // Margin for button style 2
$checkbox-size:                  1rem;             // Size for checkbox/radio [3]
$checkbox-back-color:            $input-back-color;// Background clor for checkbox/radio
$checkbox-fore-color:            $input-fore-color;// Text/mark color for checkbox/radio
$checkbox-border-thickness:      $_1px;            // Border thickness for checkbox/radio
$checkbox-border-color:          #bdbdbd;          // Border color for checkbox/radio
$checkbox-border-radius:      $input-border-radius;// Border radius for checkbox/radio
$checkbox-focus-border-color: $input-focus-border-color;// Border color for checkbox/radio on focus
$checkbox-bottom-spacing:     0.375rem;            // Bottom spacing for checkbox/radio
$checkbox-disabled-opacity:   $input-disabled-opacity;// Opacity for disabled checkbox/radio
$checkbox-box-shadow:           none;              // Box shadow for checkbox/radio
$include-switch:                true;              // Should switch components be included? (`true`/`false`)
$switch-name:                   'switch';          // Class name for switch components
$switch-bar-back-color:         #c9c9c9;           // Back color for the switch's bar
$switch-on-bar-back-color:      #dcdcdc;           // Back color for the switch's bar when the switch is turned on
$switch-bar-width:              1.75rem;           // Width for the switch's bar
$switch-bar-height:             0.875rem;          // Height for the switch's bar
$switch-bar-border-style:       0;                 // Border style for the switch's bar
$switch-bar-border-radius:      0.5rem;            // Border radius for the switch's bar
$switch-bottom-spacing:         0.5rem;            // Bottom spacing for the switch's bar
$switch-bar-box-shadow:         none;              // Box shadow for the switch's bar
$switch-knob-back-color:        #e0e0e0;           // Back color for the switch's knob
$switch-on-knob-back-color:     #0277bd;           // Back color for the switch's knob when the switch is turned on
$switch-knob-width:             1.25rem;           // Width for the switch's knob
$switch-knob-height:            1.25rem;           // Height for the switch's knob
$switch-knob-border-style:      0;                 // Border style for the switch's knob
$switch-knob-border-radius:     100%;              // Borer radius for the switch's knob
$switch-knob-box-shadow:        none;              // Box shadow for the switch's knob
//   Notes:
// [1] - If the value of $include-fluid-input-group is `true`, fluid input groups will be included, using the values
//  specified in the corresponding variables. The same applies for $include-vertical-input-group and vertical input groups.
// [2] - If the value of $hide-file-inputs is `true`, all <input type="file"> elements will be hidden and the only way
//   to access them is via the use of <label> elements that are linked to them. This option is enabled by default to
//  allow for universal button styles, even for file input buttons, but can be disabled if the base style is desired.
//  If the value is `false`, some fixes will be applied to the <input type="file"> element.
// [3] - The value of $checkbox-size will determine a few variables like some `margin` values, `height`, `width` etc.
//  Variables for navigational elements
$header-height:            100px;                  // Height for <header>
$header-back-color:        transparent;            // Background color for <header>
$header-fore-color:        $primary-color;         // Text color for <header>
$header-border-style:      0;                      // Border style for <header>
$header-margin:            0;                      // Margin for <header>
$header-padding:           0.125rem 0.5rem;        // Padding for <header>
$header-box-shadow:        none;                   // Box shadow for <header>
$header-logo-name:         'logo';                 // Class name for <header>'s logo
$header-logo-font-size:    1.75rem;                // Font size for <header>'s logo
$header-logo-line-height:  1.3125em;               // Line height for <header>'s logo
$header-logo-margin:       0.0625rem 0.375rem 0.0625rem 0.0625rem;  // Margin for <header>'s logo
$header-logo-padding:      0;                      // Padding for <header>'s logo
$header-link-hover-color:  rgba(219, 207, 116, 0.4);// Hover color for <header>'s links
$header-link-margin:       0.125rem 0;             // Margin for <header>'s links
$include-header-sticky:    true;                   // Should sticky <header> elements be included? (`true`/`false`) [1]
$header-sticky-name:       'sticky';               // Class name for sticky <header>
$nav-back-color:           #eceff1;                // Background for <nav>
$nav-fore-color:           $fore-color;            // Text color for <nav>
$nav-border-style:         $_1px solid #c9c9c9;    // Border style for <nav>
$nav-border-radius:        0;                      // Border radius for <nav>
$nav-padding:              0.75rem 1rem;           // Padding for <nav>
$nav-margin:               0.5rem;                 // Margin for <nav>
$nav-box-shadow:           none;                   // Box shadow for <nav>
$nav-link-fore-color:      #145caf;                // Text color for links in <nav>
$nav-sublink-prefix:       'sublink';              // Prefix for the subcategory links in <nav>
$nav-sublink-depth:         2;                     // Amount of subcategory classes to add
$nav-sublink-padding-left:  1rem;                  // Left padding to add to subcategories
$nav-include-sublink-bar:   true;                  // Should a left border bar be added to subcategories (`true`/`false`) [2]
$nav-sublink-bar-left-position: 0.1875rem;         // Left position of subcategory bar
$nav-sublink-bar-width:    $_1px;                  // Width of the subcategory bar
$nav-sublink-bar-color:    #bdbdbd;                // Subcategory bar color
$drawer-name:              'drawer';               // Class name for the drawer component
$drawer-back-color:        $nav-back-color;        // Background color of the drawer component
$drawer-border-style:      $nav-border-style;      // Border style of the drawer component
$drawer-border-radius:     0;                      // Border radius of the drawer component
$drawer-width:             320px;                  // Width of the drawer component
$drawer-padding:           0;                      // Padding of the drawer component
$drawer-box-shadow:        none;                   // Box shadow for the drawer component
$drawer-mobile-breakpoint: 768px;                  // Mobile breakpoint for the drawer component
$drawer-normal-height:     calc(100vh - 3rem);     // Height of the drawer component when shown as normal menu
$drawer-right-name:        'right';                // Class name for the right variant of the drawer component
$drawer-persistent-name:   'persistent';           // Class name for the persisten variant of the drawer component
$drawer-toggle-name:       'drawer-toggle';        // Class name for the drawer component's toggle
$drawer-toggle-font-size:  2.5rem;                 // Font size for the drawer component's toggle icon
$drawer-toggle-line-height:0.125;                  // Line height for the drawer component's toggle icon
$drawer-toggle-top:        0.4375rem;              // Top position for the drawer component's toggle icon
$drawer-close-name:        'close';                // Class name of the close element for the drawer component
$drawer-close-top:         0.75rem;                // Top position of the drawer component's close icon
$drawer-close-right:       0.25rem;                // Right position of the drawer component's close icon
$footer-back-color:        #373737;                // Background color for <footer>
$footer-fore-color:        #f5f5f5;                // Text color for <footer>
$footer-border-style:      0;                      // Border stye for <footer>
$footer-font-size:         0.875rem;               // Font size for <footer>
$footer-margin:            1rem 0 0;               // Margin for <footer>
$footer-padding:           1.5rem 0.5rem 0.75rem;  // Padding for <footer>
$footer-link-fore-color:   #0288d1;                // Text color for links in <footer>
$include-footer-sticky:    false;                   // Should sticky <footer> elements be included (`true`/`false`) [3]
$footer-sticky-name:       'sticky';               // Class name for sticky <footer> elements
//  Notes:
// [1] - If the value of $include-header-sticky is `true`, a class will be created that will allow for sticky positioning
//  of <header> elements, using the value of $header-sticky-name for the name of the class.
// [2] - If the value of $nav-include-sublink-bar is `true`, a left-side border bar will appear next to the navigation
//  subcategories, using the values specified.
// [3] - If the value of $include-footer-bottom is `true`, a class will be created that will allow for sticky positioning
//  of <footer> elements, using the value of $footer-sticky-name for the name of the class.
//  Variables for responsive tables
$table-border-style:      $_1px solid #c9c9c9;     // Border style for <table> and children
$table-border-radius:     0;                       // Border radius for <table> and children
$table-margin:            1em auto;                  // Margin for <table>
$table-box-shadow:        none;                    // Box shadow for <table>
$table-caption-font-size:    1.5rem;               // Font size for <caption>
$table-caption-margin:       0.5rem;               // Margin for <caption>
$table-row-padding:          0.5rem;               // Padding for <tr> - both views
$table-column-padding:       0.625rem;             // Padding for <td> and <th> - desktop view
$table-head-back-color:      #e6e6e6;              // Background color for <th>
$table-head-fore-color:      $fore-color;          // Text color for <th>
$table-body-back-color:      #fafafa;              // Background color for <td>
$table-body-fore-color:      $fore-color;          // Text color for <td>
$table-mobile-breakpoint:    767px;                // Breakpoint for <table> mobile view
$table-mobile-card-spacing:  0.625rem;             // Space between <tr> cards - mobile view
$table-mobile-card-label:    'data-label';         // Attribute used to replace column headers in mobile view [1]
$table-mobile-label-font-weight:$bold-font-weight; // Font weight for mobile headers
$include-horizontal-table:    true;                // Should horizontal <table> elements be included? (`true`/`false`) [2]
$table-horizontal-name:       'horizontal';        // Class name for <table> horizontal view
$include-scrollable-table:    true;                // Should scrollable <table> elements be included? (`true`/`false`) [3]
$table-scrollable-name:       'scrollable';        // Class name for <table> scrollable view
$table-scrollable-height:     400px;               // Height for <table> scrolalble view.
$table-not-responsive-name:   'preset';            // Class name for <table> non-responsive view
$table-striped-name:          'striped';           // Class name for striped <table>
$table-striped-alt-body-back-color:    #F2F2F2;    // Alternate background color for <td> in striped <table>
//  Notes:
// [1] - The attribute specified in $table-mobile-card-label must be added manually to each and every element in the table
//  in order for their mobile headers to display properly.
// [2] - If $include-horizontal-table is `true`, then the <table> horizontal view will be included in a class defined by
//  $table-horizontal-name.
// [3] - If $include-scrollable-table is `true`, then the <table> scrollable view will be included in a class defined by
//  $table-scrollable-name and a height defined by $table-scrollable-height.
//  Variables for cards [1]
$card-name:              'card';                   // Class name for cards
$card-back-color:        #fafafa;                  // Background color for cards
$card-fore-color:        $fore-color;              // Text color for cards
$card-border-style:      $_1px solid #acacac;      // Border style for cards
$card-border-radius:     0;                        // Border radius for cards
$card-margin:            0.5rem;                   // Margin for cards
$card-box-shadow:        none;                     // Box shadow for cards
$card-section-name:            'section';          // Class name for card sections
$card-section-border-style:    $_1px solid #c9c9c9;// Border style for card sections
$card-section-padding:         0.5rem;             // Padding for card sections
$card-section-media-name:      'media';            // Class name for card media sections
$card-section-media-height:    200px;              // Height for card media setions
$card-normal-width:            320px;              // Width for normal cards
$card-size1-name:              'large';            // Class name for large cards
$card-size1-width:             480px;              // Width for large cards
$card-size2-name:              'small';            // Class name for small cards
$card-size2-width:             240px;              // Width for small cards
$card-size3-name:              'fluid';            // Class name for fluid cards
$card-size3-width:             100%;               // Width for fluid cards
$card-style1-name:             'warning';          // Class name for card style 1
$card-style1-back-color:       #ffca28;            // Background color for card style 1
$card-style1-fore-color:       $fore-color;        // Text color for card style 1
$card-style1-border-style:     $_1px solid #e8b825;// Border style for card style 1
$card-style1-border-radius:    0;                  // Border radius for card style 1
$card-style1-section-border-style:$_1px solid #e8b825;// Border style for card style 1 sections
$card-style2-name:            'error';             // Class name for card style 2
$card-style2-back-color:      #b71c1c;             // Background color for card style 2
$card-style2-fore-color:      #fafafa;             // Text color for card style 2
$card-style2-border-style:    $_1px solid #a71a1a; // Border style for card style 2
$card-style2-border-radius:   0;                   // Border radius for card style 2
$card-style2-section-border-style:$_1px solid #a71a1a;// Border style for card style 2 sections
$card-section-style1-name:        'dark';          // Class name for card section style 1
$card-section-style1-back-color:  #e0e0e0;         // Background color for card section style 1
$card-section-style1-fore-color:  $fore-color;     // Text color for card section style 1
$card-section-style2-name:        'darker';        // Class name for card section style 2
$card-section-style2-back-color:  #bdbdbd;         // Background color for card section style 2
$card-section-style2-fore-color:  $fore-color;     // Text color for card section style 2
$card-section-padding1-name:    'double-padded';   // Class name for card section padding style 1
$card-section-padding1-padding:  0.75rem;          // Padding for card section padding style 1
//  Notes:
// [1] - The cards module depends heavily on the grid system module.
//  Variables for tabs
$tab-container-name:            'tabs';            // Class name for the tabs' container
$tab-container-box-shadow:      none;              // Box shadow for the tabs' container
$tab-label-back-color:          #e6e6e6;           // Background color for tabs' labels
$tab-label-fore-color:          $fore-color;       // Text color for tabs' labels
$tab-label-selected-back-color: #eeeeee;           // Background color for open tab's label
$tab-label-selected-fore-color: $fore-color;       // Text color for open tab's label
$tab-label-hover-opacity:       0.8;               // Opacity of the tab's label on hover
$tab-label-padding:             0.75rem;           // Padding for tabs' labels
$tab-label-height:              1.5rem;            // Height for tabs' labels
$tab-border-style:              $_1px solid #bdbdbd;// Border style for tabs
$tab-border-radius:             0;                 // Border radius for tabs
$tab-selected-border-color:     #0277bd;           // Selected tab's bottom border color
$tab-panel-back-color:          #fafafa;           // Background color for tabs' panels
$tab-panel-fore-color:          $fore-color;       // Text color for tabs' panels
$tab-panel-padding:             0.5rem;            // Padding for tabs' panels
$tab-panel-height:              400px;             // Height for tabs' panels
$tab-stacked-breakpoint:        767px;             // Breakpoint for tabs layout (stacked/horizontal)
$tab-stacked-name:              'stacked';         // Class name for stacked tabs
//  Variables for contextual background elements and alerts
$mark-back-color:        #0277bd;                  // Background color for <mark>
$mark-fore-color:        #fafafa;                  // Text color for <mark>
$mark-font-size:         0.9375em;                 // Font size for <mark>
$mark-line-height:       1em;                      // Line height for <mark>
$mark-border-style:      0;                        // Border style for <mark>
$mark-border-radius:     2*$_1px;                  // Border radius for <mark>
$mark-padding:           0.125em 0.25em;           // Padding for <mark>
$mark-margin:            0;                        // Margin for <mark>
$mark-box-shadow:        none;                     // Box shadow for <mark>
$mark-inline-block-name:    'inline-block';        // Class name for <mark> inline block styling
$mark-variant1-name:        'secondary';           // Class name for <mark> variant 1
$mark-variant1-back-color:  #e53935;               // Background color for <mark> variant 1
$mark-variant2-name:        'tertiary';            // Class name for <mark> variant 2
$mark-variant2-back-color:  #689f38;               // Background color for <mark> variant 2
$mark-style1-name:          'tag';                 // Class name for <mark> style 1
$mark-style1-border-style:  0;                     // Border style for <mark> style 1
$mark-style1-border-radius: 200px;                 // Border radius for <mark> style 1
$mark-style1-padding:       0.25em 0.5em;          // Padding for <mark> style 1
$mark-style2-name:          'inline-block';        // Class name for <mark> style 2
$mark-style2-border-style:  0;                     // Border style for <mark> style 2
$mark-style2-border-radius: 2*$_1px;               // Border radius for <mark> style 2
$mark-style2-padding:       0.375em;               // Padding for <mark> style 2
$mark-style2-font-size:     1em;                   // Font size for <mark> style 2
$mark-style2-line-height:   1.375em;               // Line height for <mark> style 2
$toast-name:                'toast';               // Class name for toast component
$toast-bottom:              1.5rem;                // Bottom position for toasts
$toast-back-color:          #424242;               // Background color for toasts
$toast-fore-color:          #fafafa;               // Text color for toasts
$toast-border-style:        0;                     // Border style for toasts
$toast-border-radius:       2rem;                  // Border radius for toasts
$toast-padding:             0.75rem 1.5rem;        // Padding for toasts
$toast-box-shadow:          none;                  // Box shadow for toasts
$toast-style1-name:         'small';               // Class name for toast style 1
$toast-style1-border-style: 0;                     // Border style for toast style 1
$toast-style1-border-radius:1.5rem;                // Border radius for toast style 1
$toast-style1-padding:      0.5rem 1rem;           // Padding for toast style 1
$toast-style2-name:         'large';               // Class name for toast style 2
$toast-style2-border-style: 0;                     // Border style for toast style 2
$toast-style2-border-radius:3rem;                  // Border radius for toast style 2
$toast-style2-padding:      1.125rem 2.25rem;      // Padding for toast style 2
$include-tooltip:           true;                  // Should tooltips be included? (`true`/`false`) [1]
$tooltip-name:              'tooltip';             // Class name for the tooltip component
$tooltip-back-color:        $fore-color;           // Background color for tooltips
$tooltip-fore-color:        #fafafa;               // Text color for tooltips
$tooltip-border-radius:     2*$_1px;               // Border radius for tooltips
$tooltip-tail-size:         0.5rem;                // The size of the tooltip's tail
$tooltip-padding:           0.5rem;                // Padding for tooltips
$tooltip-box-shadow:        none;                  // Box shadow for tooltip-box-shadow
$tooltip-bottom-name:       'bottom';              // Class name for bottom tooltip
$include-modal:             true;                  // Should modals be included? (`true`/`false`) [2]
$modal-name:                'modal';               // Class name for the modals
$modal-back-color:          #000;                  // Background color of the modal overlay
$modal-back-opacity:        0.45;                  // Background opacity of the modal overlay
$modal-close-name:          'close';               // Class name of the close element od the modal component.
$modal-close-top:           0.75rem;               // Top position of the modal component's close icon
$modal-close-right:         0.25rem;               // Right position of the modal component's close icon
//   Notes:
// [1] - If the value of $include-tooltip is `true`, a tooltip component will be creatd based on the values of the variables
//  for the tooltip, as explained in their descriptions. Tooltip mixins will only work properly if the tooltip component is
//  included.
// [2] - If the value of $include-modal is `true`, a modal component will be created based on the values of the variables
//  for the modal, as explained in their descriptions.
//  Variables for progress elements and spinners
$progress-back-color:          #e0e0e0;            // Background color for <progress>
$progress-fore-color:          #0277bd;            // Progress bar color for <progress>
$progress-height:              0.625rem;           // Height of <progress>
$progress-max-value:           1000;               // Arithmetic max value of <progress>
$progress-border-style:        0;                  // Border style for <progress>
$progress-border-radius:       2*$_1px;            // Border radius for the <progress> container
$progress-top-bottom-margin:   0.5rem;             // Top and bottom margin for <progress>
$progress-left-right-margin:   0.5rem;             // Left and right margin for <progress>
$progress-box-shadow:          none;               // Box shadow for <progress>
$progress-inline-name:         'inline';           // Class name for inline <progress>
$progress-inline-width:        60%;                // Width for inline <progress>
$progress-variant1-name:       'secondary';        // Class name for <progress> variant 1
$progress-variant1-fore-color: #e53935;            // Progress bar color for <progress> variant 1
$progress-variant2-name:       'tertiary';         // Class name for <progress> variant 2
$progress-variant2-fore-color: #689f38;            // Progress bar color for <progress> variant 2
$spinner-donut-name:      'spinner-donut';         // Class name for donut spinner
$spinner-donut-border-thickness:    0.25rem;       // Border thickness for donut spinner
$spinner-donut-back-color:          #e3f2fd;       // Background color for donut spinner
$spinner-donut-fore-color:          $primary-color;// Foreground color for donut spinner
$spinner-donut-size:                1.25rem;       // Size for donut spinner
$spinner-donut-variant1-name:       'secondary';   // Class name for donut spinner variant 1
$spinner-donut-variant1-back-color: #ffebee;       // Background color for donut spinner variant 1
$spinner-donut-variant1-fore-color: #c62828;       // Foreground color for donut spinner variant 1
$spinner-donut-variant2-name:       'tertiary';    // Class name for donut spinner variant 2
$spinner-donut-variant2-back-color: #e8f5e9;       // Background color for donut spinner variant 2
$spinner-donut-variant2-fore-color: #2e7d32;       // Foreground color for donut spinner variant 2
$spinner-donut-style1-name:         'large';       // Class name for donut spinner style 1
$spinner-donut-style1-size:         2rem;          // Size for donut spinner style 1
$spinner-donut-style1-border-thickness:  0.375rem; // Border thickness for donut spinner style 1
//  Variables for utilities and helper classes
$hidden-name:             'hidden';                // Class name for hidden elements
$visually-hidden-name:    'visually-hidden';       // Class name for visually hidden elements
$breadcrumbs-name:        'breadcrumbs';           // Class name for breadcrumbs
$breadcrumbs-back-color:  #e6e6e6;                 // Background color for breadcrumbs
$breadcrumbs-margin:      0.5rem;                  // Margin for breadcrumbs
$breadcrumbs-height:      2rem;                    // Height of the breadcrumbs
$breadcrumbs-separator-width:  0.125rem;           // Width of the breadcrumbs' separator
$breadcrumbs-border-style:     0;                  // Border style for breadcrumbs
$breadcrumbs-border-radius:    0;                  // Border radius for breadcrumbs
$breadcrumbs-box-shadow:       none;               // Box shadow for breadcrumbs
$close-icon-name:              'close';            // Class name for close icon
$close-icon-size:              1.5rem;             // Size of the close icon
$close-icon-back-color:        #e6e6e6;            // Background color for the close icon
$close-icon-fore-color:        #212121;            // Foreground color for the close icon
$border-generic-name:          'bordered';         // Class name for generic border style
$border-radial-style1-name:    'rounded';          // Class name for radial border style 1
$border-radial-style1-radius:  2*$_1px;            // Border radius for radial border style 1
$border-radial-style2-name:    'circular';         // Class name for radial border style 2
$border-radial-style2-radius:  50%;                // Border radius for radial border style 2
$box-shadow-style1-name:       'shadowed';         // Class name for generic box-shadow style 1
$box-shadow-style1-value:                          // Box shadow value for generic box-shadow style 1
  0 4*$_1px 4*$_1px 0 rgba(0, 0, 0, 0.125),  0 2*$_1px 2*$_1px -2*$_1px rgba(0, 0, 0, 0.25);
$responsive-margin-name:      'responsive-margin'; // Class name for responsive margin
$responsive-margin-small-value:   0.25rem;         // Margin value for responsive margin on small screens
$responsive-margin-medium-value:  0.375rem;        // Margin value for responsive margin on medium screens
$responsive-margin-large-value:   0.5rem;          // Margin value for responsive margin on large screens
$responsive-padding-name:    'responsive-padding'; // Class name for responsive padding
$responsive-padding-small-value:  0.125rem 0.25rem;// Padding value for responsive padding on small screens
$responsive-padding-medium-value: 0.25rem 0.375rem;// Padding value for responsive padding on medium screens
$responsive-padding-large-value:  0.375rem 0.5rem; // Padding value for responsive padding on large screens
$responsive-hidden-prefix:          'hidden';      // Class name prefix for responsive hidden elements
$responsive-visually-hidden-prefix: 'visually-hidden'; // Class name prefix for responsive visually hidden elements
$include-floats:              false;               // Should floats be included? (`true`/`false`) [1]
$include-clearfix:            false;               // Should clearfix be included? (`true`/`false`) [2]
$include-center-block-name:   false;               // Should center block be included? (`true`/`false`) [3]
//   Notes:
// [1] - If the value of $include-floats is `true`, float classes will be created using the value of $float-prefix to determine the class
//  names that will be used.
// [2] - If the value of $include-clearfix is true, a clearfix class will be created using the value of $clearfix-name to determine the
//  class name of the clearfix component.
// [3] - If the value of $include-center-block is true, a center block class will be created using the value of $center-block-name to
//  determine the class name of the center block class.
// Load modules. If you do not want to use a module, comment out its `@import` statement, along with any mixin `@include`s that are part of
// that module and marked as such below it.
@import 'mini/core';
@import 'mini/grid';
@import 'mini/input_control';
/*
  Custom elements for forms and input elements.
*/
@include make-button-alt-color ($button-variant1-name, $button-variant1-back-color,
  $button-variant1-back-opacity, $button-variant1-hover-back-opacity, $button-variant1-fore-color);
@include make-button-alt-color ($button-variant2-name, $button-variant2-back-color,
  $button-variant2-back-opacity, $button-variant2-hover-back-opacity, $button-variant2-fore-color);
@include make-button-alt-color ($button-variant3-name, $button-variant3-back-color,
  $button-variant3-back-opacity, $button-variant3-hover-back-opacity, $button-variant3-fore-color);
@include make-button-alt-color ($button-variant4-name, $button-variant4-back-color,
  $button-variant4-back-opacity, $button-variant4-hover-back-opacity, $button-variant4-fore-color);
@include make-button-alt-style ($button-style1-name, $button-style1-border-style,
  $button-style1-border-radius, $button-style1-padding, $button-style1-margin);
@include make-button-alt-style ($button-style2-name, $button-style2-border-style,
  $button-style2-border-radius, $button-style2-padding, $button-style2-margin);
@import 'mini/navigation';
@import 'mini/table';
// @import 'mini/card';
/*
  Custom elements for cards and containers.
*/
// @include make-card-alt-size ($card-size1-name, $card-size1-width);
// @include make-card-alt-size ($card-size2-name, $card-size2-width);
// @include make-card-alt-size ($card-size3-name, $card-size3-width);
// @include make-card-alt-color ($card-style1-name, $card-style1-back-color, $card-style1-fore-color,
//   $card-style1-border-style, $card-style1-border-radius, $card-style1-section-border-style);
//   @include make-card-alt-color ($card-style2-name, $card-style2-back-color, $card-style2-fore-color,
//     $card-style2-border-style, $card-style2-border-radius, $card-style2-section-border-style);
// @include make-card-section-alt-color ($card-section-style1-name, $card-section-style1-back-color,
//   $card-section-style1-fore-color);
// @include make-card-section-alt-color ($card-section-style2-name, $card-section-style2-back-color,
//   $card-section-style2-fore-color);
// @include make-card-section-alt-style ($card-section-padding1-name, $card-section-padding1-padding);
// @import 'mini/tab';
// @import 'mini/contextual';
/*
  Custom contextual background elements and alerts.
*/
// @include make-mark-alt-color ($mark-variant1-name, $mark-variant1-back-color);
// @include make-mark-alt-color ($mark-variant2-name, $mark-variant2-back-color);
// @include make-mark-alt-style ($mark-style1-name, $mark-style1-border-style,
//   $mark-style1-border-radius, $mark-style1-padding);
// @include make-mark-alt-style ($mark-style2-name, $mark-style2-border-style,
//   $mark-style2-border-radius, $mark-style2-padding, $mark-style2-font-size, $mark-style2-line-height);
// @include make-toast-alt-style($toast-style1-name, $toast-style1-border-style,
//   $toast-style1-border-radius, $toast-style1-padding);
// @include make-toast-alt-style($toast-style2-name, $toast-style2-border-style,
//   $toast-style2-border-radius, $toast-style2-padding);
@import 'mini/progress';
/*
  Custom elements for progress elements and spinners.
*/
@include make-progress-inline ($progress-inline-name, $progress-inline-width);
@include make-progress-alt-color ($progress-variant1-name, $progress-variant1-fore-color);
@include make-progress-alt-color ($progress-variant2-name, $progress-variant2-fore-color);
@include make-spinner-donut-alt-color ($spinner-donut-variant1-name, $spinner-donut-variant1-back-color,
  $spinner-donut-variant1-fore-color);
@include make-spinner-donut-alt-color ($spinner-donut-variant2-name, $spinner-donut-variant2-back-color,
  $spinner-donut-variant2-fore-color);
@include make-spinner-donut-alt-style ($spinner-donut-style1-name, $spinner-donut-style1-size,
  $spinner-donut-style1-border-thickness);
@import 'mini/utility';
/*
  Custom elements for utilities and helper classes.
*/
@include make-border-generic ($border-generic-name);
@include make-border-radial-style ($border-radial-style1-name, $border-radial-style1-radius);
@include make-border-radial-style ($border-radial-style2-name, $border-radial-style2-radius);
@include make-margin-responsive ($responsive-margin-name, $responsive-margin-small-value,
  $responsive-margin-medium-value, $responsive-margin-large-value);
@include make-padding-responsive ($responsive-padding-name, $responsive-padding-small-value,
  $responsive-padding-medium-value, $responsive-padding-large-value);
@include make-box-shadow-generic ($box-shadow-style1-name, $box-shadow-style1-value);
@include make-hidden-responsive ($responsive-hidden-prefix);
@include make-visually-hidden-responsive ($responsive-visually-hidden-prefix, $grid-medium-breakpoint, $grid-large-breakpoint);