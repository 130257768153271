$carousel-items: 5;  // this will blow up the size of the CSS file
$carousel-preview-items: 1; // no to show left and right
$carousel-class-prefix: 'c';

// Static view

.carousel-spacing {
  margin-bottom: 100px;
}

.carousel {
  $ball-spacing: 105px;
  height: calc(100% + #{$ball-spacing});
  overflow: hidden;
  position: relative;
  z-index: 5;
  ul.nav {
    display: flex;
    justify-content: space-between;
    label {
      padding: 0;
      margin: 0;
      display: block;
    }
  }
  div.track {
    position: absolute;
    max-width: 760px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 230px;
    transition: transform .3s;

    ul {
      position: relative;
      padding: 0;
      margin: 0;
      list-style-type: none;
      li {
        padding: 0 .5rem;
        box-sizing: border-box;
      }
    }
  }

  .track.nav {
    position: relative;
    top: calc(100% - #{$ball-spacing});
    transform: translateY(-50%);
  }
  img {
    border-radius: 50%;
    border: $primary-color solid;
    border-width: calc(2px + .3vw);
    display: block;
    max-width: 189px;
    width: 28vw;
  }
}

.carousel-heading {
  left: 0; right: 0;
  position: absolute;
  top: 173px;
  text-align: center;
  color: #353B45;
  font-family: $contrast-heading-font !important;
  font-weight: bold;
  font-size: 1.6rem;
  text-transform: uppercase;
}

@media (max-width: $grid-medium-breakpoint - 1px) { // sm
  .carousel {
    .track.nav {
      -webkit-mask-image: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 15%, rgba(0,0,0,1) 85%, rgba(0,0,0,0) 100%);
      .nav-wrap {
        display: inline-block;
        margin-left: 50%;
        transform: translateX(-50%);
      }
      ul {
        img {
          width: auto;
        }
      }
    }
    .carousel-heading, .track.top {
      transform: translateY(-50px);
    }
  }
}

// Interactive section

.carousel-activator {
  display: none;
  & ~ .nav li {
    display: none;
  }
}

@for $i from 1 through $carousel-items {
  .carousel-activator:nth-of-type(#{$i}) {
    // Show preview items
    @for $j from $i - $carousel-preview-items through $i - 1 {
      $jrel: $j;
      @if $j < 1 {
        $jrel: $carousel-items + $j;
      }
      &:checked ~ div.track.nav li:nth-of-type(#{$jrel}) {
        display: block;
        order: $j - ($i - $carousel-preview-items) + 1;
      }
    }
    &:checked ~ div.track.nav li:nth-of-type(#{$i}) {
      display: block;
      order: $carousel-preview-items + 1;
      cursor: default;
      img {
        cursor: default;
      }
    }
    @for $j from $i + 1 through $i + $carousel-preview-items {
      $jrel: $j;
      @if $j > $carousel-items {
        $jrel: $j - $carousel-items;
      }
      &:checked ~ div.track.nav li:nth-of-type(#{$jrel}) {
        display: block;
        order: $carousel-preview-items + 1 + ($j - $i);
      }
    }
  }

}
