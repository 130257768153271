#main-content-body {
  padding-bottom: 5rem;
  & h2:first-child {
    margin-top: 1rem;
  }
}

.column-title.split {
  margin-top: 0.9em;
  margin-bottom: 0.9em;
  font-family: $contrast-heading-font !important;
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  color: #C4C4C4;
  .subtitle {
    font-weight: normal;
    display: block;
    font-family: $heading-font;
    text-transform: none;
    color: white;
    font-size: 1.5rem;
    margin-top: 0.2em;
    line-height: 1.6rem;
  }
}

.column-title {
  font-family: $heading-font;
  color: white;
  font-size: 2.05rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.two-col-head {
  background: rgba($primary-color, .95);
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3rem;
    background: rgba($primary-color, .95);
    left: 100%;
  }

  // Line above
  .deco1 {
    position: absolute;
    background: rgba(#53677F, .70);
    height: 1.5ex;
    bottom: 100%;
  }

  @media (min-width: #{$grid-large-breakpoint}) {
    .deco1 {
      width: calc(100% + 2em);
      bottom: 100%;
      right: 0;
    }
  }

  $right-deco-width: 2.5rem;
  @media (max-width: $grid-large-breakpoint - 1px) {
    &::after {
      display: none;
    }
    .deco1 {
      width: calc(100% - 4rem);
      left: 0;
      &::before {
        content: "";
        position: absolute;
        width: 4rem;
      }
      &::after {
        content: "";
        position: absolute;
        height: #{(1.5ex * 0.66)};
        width: $right-deco-width;
        left: 100%;
        bottom: 0;
        background: #DFDCB0;
      }
    }
  }
}


@media (min-width: #{$grid-large-breakpoint}) {
  .side-content {
    padding-bottom: 10em;
    position: relative;
    &::after {
      content: "";
      display: block;
      height: 100%;
      width: 0;
      border-left: 1px dotted #c9c9c9;
      position: absolute;
      top: 0;
      right: 0.25rem;
    }
  }

  .side-content:empty::after {
    display: none;
  }

  .side-menu-list {
    margin-right: 0;
    li {
      border-bottom: 1px dotted #c9c9c9;
      margin-right: 1em !important;
    }
  }
}

.two-col-head-deco {
  position: relative;
  .deco1 {
    position: absolute;
    width: 3rem;
    height: 1.5ex;
    background: #DFDCB0;
    top: 100%;
    z-index: 200;
    left: -3rem;
  }
  .deco2 {
    position: absolute;
    left: 0;
    width: 50%;
    height: 60%;
    bottom: 0;
    background: rgba(#53677F, .70);
  }
  .deco3 {
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
    bottom: 0;
    background: rgba($primary-color, .95);
  }
}

.side-heading {
  font-family: $contrast-heading-font !important;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.9rem;
  color: #595959;
  margin-bottom: 0.7em;
  margin-top: 0.7em;
  margin-left: 0;
}

aside:first-child .side-heading:first-child {
  margin-top: 1.6rem;
}

.side-menu-list {
  margin-left: 0;
  list-style-type: none;
  padding-left: 0;
  margin-right: 1rem;
  li {
    display: block;
    padding: 0.125em 0;
    margin: 0;
    line-height: 1;
  }
  a {
    font-style: italic;
    text-decoration: none;
    color: #7F7F7F;
    font-size: 0.9em;
    position: relative;
    padding: .3ex;
    margin-left: -.3ex;
  }
  @include icon-font {
    li.active a::after {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      position: relative;
      left: .25rem;
      color: #999;
      font-size: .7em;
      content: fa-content($fa-var-arrow-left);
    }
  }
}

.go-back {
  a {
    font-style: italic;
    text-decoration: none;
    color: #7F7F7F;
    font-size: 0.9em;
    position: relative;
    // padding: .3ex;
    margin-left: -.3ex;
  }
  @include icon-font {
    a {
      left: -1em;

      &::before{
        color: #7F7F7F;
        font-size: .7em;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        width: 1.5em;
        display: inline-block;
        content: fa-content($fa-var-angle-double-left);
        bottom: .1ex;
        position: relative;
      }
    }
  }
}

@media (max-width: $grid-large-breakpoint - 1px) {
  .go-back a {
    left: 0 !important;
  }
}

.side-text {
  font-family: $contrast-font;
  color: #595959;
  margin-left: 0;
  line-height: 1.1em;
  font-size: 0.9em;
  margin-top: 1rem;
  margin-bottom: 0.5em;
}

@media (max-width: $grid-large-breakpoint - 1px) {
  .side-text {
    margin-top: .5rem !important;
  }
}

.two-col-body {
  background: $back-color;
  align-content: flex-start;
  min-height: 400px;
  position: relative;
  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3rem;
    background: $back-color;
  }
  &:before {
    right: 100%;
  }
  &:after {
    left: 100%;
  }
  @media (max-width: $grid-large-breakpoint - 1px) {
    &::before, &::after {
      display: none;
    }
  }
}

@media (max-width: $grid-medium-breakpoint - 1px) {
  .contact-info-sm {
    position: relative;
    z-index: 1;
    color: #74682a;
    font-size: .8em;
    & > :first-child {
      padding-top: .5rem;
    }
    & > :last-child {
      padding-bottom: .5rem;
    }
    & > :first-child:before {
      content: "";
      position: absolute;
      left: -.5rem;
      right: -.5rem;
      top: 0;
      bottom: 0;
      background: #F5F2E1;
      z-index: -10;
    }
    a {
      color: #4f535f;
    }
    .grid-sm {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
      align-items: start;
    }
  }

  #main-content-body {
    & h2:first-child {
      text-align: center;
    }
  }
}

.leader-marker ~ .contact-info {
  @extend .content-view-span;
}

.contact-info-heading {
  font-family: $contrast-heading-font;
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
  font-size: 1em;
  text-align: center;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

@media (max-width: $main-width - 1px) {
  #main-content {
    max-width: $main-width;
    width: 100% !important;
  }

  .side-nav {
    background: rgb(30, 30, 30);
    margin-left: -.5rem;
    margin-right: -.25rem;
    // margin-bottom: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
    .side-heading {
      color: #999;
      margin-top: .25rem !important;
    }

    .side-menu-list {
      margin-top: .25rem;
      margin-bottom: .5rem;
      margin-right: 0;

      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
      grid-auto-rows: 2.25em;
      grid-gap: 1px;
      li {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #353535;
        &.active {
          background: #AAA;
          a {
            text-decoration: none;
            color: #353535 !important;
          }
        }
        a {
          font-family: $contrast-heading-font;
          font-weight: bold;
          font-size: .9em;
          font-style: normal;
          position: static;
          padding: .25rem;
          margin: 0;
          color: #AAA !important;
          text-align: center;
          &::after { display: none; }
        }
      }
    }
  }

  .side-heading {
    margin-top: 0.7em !important;
  }
}
