.mdr-obfs {
  direction: rtl;
  unicode-bidi: bidi-override;
  em {
    &:before { content: " ["; }
    &:after { content: "] "; }
  }
  span {
    display: none;
  }
}
